var variablen = ["{Ort}", "{Adresse}", "{Zeit}", "{Filliale}", "{Marke}"];
const createExcel = document.getElementById("createExcel");
const uploudExcel = document.getElementById("uploudExcel");
const createAudioSpots = document.getElementById("createAllAudioSpots");
var tableCreated = false;
var notyf = new Notyf();
let immutableStore = [];

createExcel.addEventListener("click", function () {
  var customerid = document.getElementById("customerid").value;
  var kampagneid = document.getElementById("kampagneid").value;

  var myContent1 = tinymce.get("intro").getContent({ format: "text" });
  var myContent2 = tinymce.get("main").getContent({ format: "text" });
  var myContent3 = tinymce.get("outro").getContent({ format: "text" });

  //Return if customerid & campagne not available, necessary for filename
  if (!customerid) return notyf.error("Kunden-ID ist nicht angebeben!");
  if (!kampagneid) return notyf.error("Kampagnen-ID ist nicht angebeben!");

  var content = myContent1 + myContent2 + myContent3;
  console.log(content);
  /*  Checks if editor input contains existing parameter and push them to an array
   * array: existingParameter,
   */
  const regex = /\{(.+?)\}/g;
  const found = content.match(regex);
  var newArray = [];

  //parameters are necessary to create excel list
  if (found === null) return notyf.error("Du hast keine Parameter angegeben!");

  found.forEach((element) => {
    console.log(element);
    element = element.replace(/[{}]/g, "");
    newArray.push(element);
  });
  console.log(newArray);

  var wb = XLSX.utils.book_new();

  wb.Probs = {
    Title: "AudioPlus",
    Subject: "AudioPlus Audio Parameter",
    Author: "Dentsu",
    CreatedDate: new Date(2022, 11, 18),
  };

  //Excel Table Name
  wb.SheetNames.push("AudioPlus Audio Parameter");

  //Adds existing Parameter from array: existingParameter into the excel sheet
  var ws_data = [newArray];
  var ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets["AudioPlus Audio Parameter"] = ws;
  XLSX.writeFile(wb, `AudioPlus-${customerid}-${kampagneid}.xlsx`);
});

// document.addEventListener('DOMContentLoaded', function () {
//   var gridDiv = document.querySelector('#myGrid');
//   new agGrid.Grid(gridDiv, gridOptions);
// });

uploudExcel.addEventListener("change", async function (e) {
  // const audioListSection = document.getElementById("audiostacks");
  const excelDisplay = document.getElementById("excel_display");
  const gridDiv = document.getElementById("myGrid");
  $("#downloadAudioFiles").prop("disabled", true);
  console.log("disabled");
  const file = e.target.files[0];
  if (!file) return;
  const data = await file.arrayBuffer();

  // console.log(file);
  // console.log(data);

  try {
    /* parse and load first worksheet */
    const wb = XLSX.read(data);
    const ws = wb.Sheets[wb.SheetNames[0]];
    const arrayData = XLSX.utils.sheet_to_json(ws);
    const rows = arrayData.length; //equal to length of table headline length
    if (rows == 0) {
      throw "Row: 0\nÜberprüfe deine Excel Datei: Beispielparameter fehlen.";
    }

    //Werte übergeben
    excelDisplay.style.display = "block";

    const columns = Object.keys(arrayData[0]);
    var resultArr = [];

    columns.forEach((prop) => {
      const obj = { ["field"]: prop };
      resultArr.push(obj);
    });

    const gridOptions = {
      columnDefs: resultArr,

      defaultColDef: {
        editable: true,
        resizable: true,
        animateRows: true,
      },
      rowData: arrayData,
    };

    if (tableCreated == false) {
      var eGridDiv = document.querySelector("#myGrid");
      // new Grid(eGridDiv, this.gridOptions);
      new agGrid.Grid(gridDiv, gridOptions);
      window.gridOptions = gridOptions;
      tableCreated = true;
    } else {
      //Change frontend via api
      window.gridOptions.api.setColumnDefs(resultArr);
      window.gridOptions.api.setRowData(arrayData);

      //Store changes in window
      window.gridOptions.columnDefs = resultArr;
      window.gridOptions.rowData = arrayData;
      console.log(gridOptions);
    }
  } catch (error) {
    console.log(error);
    notyf.error(error);
  }
});

// $('#delete_row').click(function(){

//   const selectedRowNodes = agGrid.gridOptions.api.getFocusedCell();
//   console.log(agGrid.gridOptions.api)
//   console.log(selectedRowNodes);

//   const selectedIds = selectedRowNodes.map(function (rowNode) {
//     return rowNode.id;
//   });
//   console.log(immutableStore)
//   immutableStore = immutableStore.filter(function (dataItem) {
//     return selectedIds.indexOf(dataItem.symbol) < 0;
//   });
//   agGrid.gridOptions.api.setRowData(immutableStore);
// })

// export { agGridInstance, gridOptions };
